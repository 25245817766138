.file-seal-container[data-v-fa89fba6] {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.file-seal-container .seals-wrapper[data-v-fa89fba6] {
    width: 300px;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.file-seal-container .seals-wrapper .seal-title[data-v-fa89fba6] {
      width: 100%;
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #ddd;
      padding: 0px 10px;
}
.file-seal-container .seals-wrapper .seals-drag-area[data-v-fa89fba6] {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      height: calc(100% - 40px);
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -ms-flex-line-pack: start;
          align-content: flex-start;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      padding: 20px 10px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
}
.file-seal-container .seals-wrapper .seals-drag-area .seals-drag-item[data-v-fa89fba6] {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        width: 80px;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        margin-bottom: 10px;
        margin-left: 10px;
}
.file-seal-container .seals-wrapper .seals-drag-area .seals-drag-item img[data-v-fa89fba6] {
          width: 80px;
          height: 65px;
}
.file-seal-container .seals-wrapper .seals-drag-area .seals-drag-item span[data-v-fa89fba6] {
          display: block;
          height: 20px;
          line-height: 20px;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
}
.file-seal-container .pdf-drag-wrapper[data-v-fa89fba6] {
    width: calc(100% - 300px);
    height: 100%;
    background: #333;
    padding-bottom: 10px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
.file-seal-container .pdf-drag-wrapper .pdf-tools[data-v-fa89fba6] {
      position: relative;
      width: 100%;
      margin: 0 auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      padding: 10px 10px;
      border-bottom: 1px solid #ddd;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
}
.file-seal-container .pdf-drag-wrapper .pdf-tools .left-btns[data-v-fa89fba6] {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        width: 50%;
}
.file-seal-container .pdf-drag-wrapper .pdf-tools .left-btns .page-btn[data-v-fa89fba6] {
          background: #000;
          padding: 2px 10px;
          border-radius: 4px;
          cursor: pointer;
          color: #fff;
          margin-left: 10px;
}
.file-seal-container .pdf-drag-wrapper .pdf-tools .left-btns .page-btn--disabled[data-v-fa89fba6] {
            cursor: not-allowed;
}
.file-seal-container .pdf-drag-wrapper .pdf-tools .left-btns .page-spliter[data-v-fa89fba6] {
          margin-left: 10px;
          color: #fff;
}
.file-seal-container .pdf-drag-wrapper .pdf-tools .left-btns[data-v-fa89fba6]:first-of-type(1) {
          margin-left: 10px;
}
.file-seal-container .pdf-drag-wrapper .pdf-tools .right-btns[data-v-fa89fba6] {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
            -ms-flex-pack: end;
                justify-content: flex-end;
        width: 50%;
}
.file-seal-container .pdf-drag-wrapper .pdf-tools .right-btns .save-btn[data-v-fa89fba6] {
          background: #000;
          padding: 2px 10px;
          border-radius: 4px;
          cursor: pointer;
          color: #fff;
}
.file-seal-container .pdf-drag-wrapper .pdf-tools .right-btns .save-btn--disabled[data-v-fa89fba6] {
            cursor: not-allowed;
}
.file-seal-container .pdf-drag-wrapper .pdf-area[data-v-fa89fba6] {
      position: relative;
      width: 100%;
      height: calc(100% - 60px);
      margin-top: 10px;
      overflow-y: scroll;
}
.file-seal-container .pdf-drag-wrapper .pdf-area .pdf-dragable-area[data-v-fa89fba6] {
        position: relative;
        width: 800px;
        max-height: 1131px;
        margin: 0 auto;
}
.file-seal-container .pdf-drag-wrapper .pdf-area .pdf-dragable-area .pdf-show-area[data-v-fa89fba6] {
          width: 100%;
}
.file-seal-container .pdf-drag-wrapper .pdf-area .pdf-dragable-area .seal-moved[data-v-fa89fba6] {
          width: 120px;
          position: absolute;
          z-index: 999;
}
.file-seal-container[data-v-fa89fba6] .ghost {
    display: none;
}
